<template>
    <div class="app-container">
        <Title v-if="isAdd" name='添加地址'></Title>
        <Title v-else name='修改地址'></Title>
        <el-card class="box-card" style="box-shadow:none">
            <el-form ref="address" :rules="rules" :model="address" label-width="93px">
                <el-form-item label="收货人名称:" prop="name">
                    <el-input v-model="address.name" size="mini" style="width:300px"/>
                </el-form-item>
                <el-form-item label="手机号码:" prop="mobile">
                    <el-input v-model="address.mobile" :maxlength="11" size="mini" style="width:300px"/>
                </el-form-item>
                <el-form-item label="省份:" prop="provinceId">
                    <el-select v-model="address.provinceId" @change="handleChangeCity"  size="mini" style="width:300px">
                        <el-option v-for="item in province" :key="item.value" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="城市:" prop="cityId">
                    <el-select v-model="address.cityId" @change="handleChangeArea"  size="mini" style="width:300px">
                        <el-option v-for="item in city" :key="item.value" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="区域:" prop="areaId">
                    <el-select v-model="address.areaId"  size="mini" style="width:300px">
                        <el-option v-for="item in area" :key="item.value" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="详细地址:" prop="address">
                    <el-input v-model="address.address"  size="mini" style="width:600px" />
                </el-form-item>
            </el-form>
        </el-card>
        <div class="op-container">
            <el-button @click="handleCancel" style="font-size:12px;padding:6px 15px">取消</el-button>
            <el-button v-if="isAdd" type="primary" @click="handleSaveAddress('address')" style="font-size:12px;padding:6px 15px">添加</el-button>
            <el-button v-else type="primary" @click="handleSaveAddress('address')" style="font-size:12px;padding:6px 15px">修改</el-button>
        </div>
    </div>
</template>

<script>
    import {regionList,detailAddress,saveAddress} from "../../api";
    import {regRule} from "../../../../utils/validate";
    import Title from '../../component/Title';
    export default {
        name: "Add",
        data(){
            const phone=(rule, value, callback) =>{
                if (!value) {
                    return callback(new Error('请输入手机号码'));
                } else {
                    const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            return{
                title:undefined,
                isAdd:undefined,
                address:{
                    name:'',
                    mobile:'',
                    provinceId: '',
                    cityId: '',
                    areaId: '',
                    address:''
                },
                province:[],
                city:[],
                area:[],
                addressId:undefined,
                rules:{
                    name: [{ required: true, message: '收货人不能为空', trigger: 'blur' }],
                    mobile: [{ required: true, validator:phone, trigger: ['change','blur'] }],
                    provinceId: [{ required: true, message: '请选择省份', trigger: ['blur','change'] }],
                    cityId: [{ required: true, message: '请选择城市', trigger: ['blur','change']}],
                    areaId: [{ required: true, message: '请选择区域', trigger: ['blur','change'] }],
                    address: [{ required: true, message: '详细地址不能为空', trigger: 'blur' }],
                }
            }
        },
        created() {
            this.handleProvince(0)
            if (this.$route.query.id == null) {
                this.isAdd=true;
                return
            }
            this.addressId = this.$route.query.id
            this.isAdd=false;
            this.handleAddress(this.addressId)
        },
        methods:{
            handleProvince(pid){
                regionList({"pid":pid}).then(res=>{
                    this.province=res.data
                })
            },
            handleChangeCity(pid){
                this.address.cityId=""
                this.address.areaId=""
                regionList({"pid":pid}).then(res=>{
                    this.city=res.data
                })
            },
            handleCity(pid){
                regionList({"pid":pid}).then(res=>{
                    this.city=res.data
                })
            },
            handleChangeArea(pid){
                this.address.areaId=""
                regionList({"pid":pid}).then(res=>{
                    this.area=res.data
                })
            },
            handleArea(pid){
                regionList({"pid":pid}).then(res=>{
                    this.area=res.data
                })
            },
            handleAddress(aid){
                detailAddress({"id":aid}).then(res=>{
                    this.address=res.data
                    this.handleCity(this.address.provinceId)
                    this.handleArea(this.address.cityId)
                })
            },
            handleSaveAddress(formName){
                this.$refs[formName].validate((valid) => {
                    if(!valid){
                        return
                    }
                    saveAddress(this.address).then(res=>{
                        if(res.errno==0){
                            this.$notify({
                                title:"提示",
                                message:"成功",
                                type:"success"
                            })
                            this.$router.push({ path: '/User/Address' })
                        }
                    })
                })

            },
            handleCancel: function() {
                this.$router.push({ path: '/User/Address' })
            },
        },
        components: {
            Title
        }
    }
</script>

<style lang='less' scoped>
    .op-container {
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
    }

    .app-container {
        background: #fff;
        padding: 0
    }
    /deep/.el-form-item__label {
        font-size: 12px;
        padding-right: 6px;
    }
</style>
